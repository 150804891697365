
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmDropdownFilter from '@/components/shared/dropdowns/TmDropdownFilter.vue'
import { colorPaletteOptions } from '@/definitions/_general/_data/colorPaletteOptions'
import type { ColorPaletteType } from '@/definitions/_general/_types/colorPalette'
import { TagsFilter, ChatStatusesFilter, ChannelFilter, AssigneeFilter, ListsFilter } from '@/definitions/shared/dropdowns/data'
import DropdownFilterStatusesSingle from '@/components/shared/filters/dropdown/DropdownFilterStatusesSingle.vue'
import DropdownFilterAutocomplete from '@/components/shared/filters/dropdown/DropdownFilterAutocomplete.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'

export default defineComponent({
  components: {
    DropdownFilterAutocomplete,
    DropdownFilterAssignee,
    WhiteBlock,
    TmFormLine,
    DropdownFilterStatusesSingle,
    TmDropdownFilter,
    DetailsHero,
    InfoCard,
    TmColorPalette,
    ChatsSettingsPage,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Filtered views', url: { name: 'base.chats.settings.filteredViews' } },
      { label: 'My custom view 1' },
    ]
    const name = ref('My custom view 1')
    const colorPaletteItems = colorPaletteOptions.filter((e: ColorPaletteType) => !e.outline)
    const color = ref(colorPaletteItems[0])
    const filterItems = ref([
      ChatStatusesFilter,
      AssigneeFilter,
      TagsFilter,
      ListsFilter,
      ChannelFilter,
    ])
    const filters = ref(filterItems.value)

    return {
      filters,
      color,
      filterItems,
      colorPaletteItems,
      breadcrumbs,
      name,
    }
  },
})
